<template>
	<div class="login-container">
		<div class="left">
			<img src="../../../static/images/login-bg.png" />
		</div>
		<div class="right">
			<div class="right-inner">
				<div class="loginT">知识产权数字系统</div>
			<el-form ref="ruleForm" :model="ruleForm"  :rules="rules" :hide-required-asterisk="true">
				<el-form-item  prop="user">
					<el-input v-model="ruleForm.user" placeholder="请输入用户名" />
				</el-form-item>
				<el-form-item  prop="password">
					<el-input v-model="ruleForm.password" placeholder="请输入密码" type="password" show-password />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="loginBtn" :loading="loading" @click="onLogin('ruleForm')">
						登录
					</el-button>
				</el-form-item>
			</el-form>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {login} from "../../api/api";
	export default {
		name: 'home',
		data() {
			return {
				loading: false,
				ruleForm: {
					user: localStorage.getItem('account') || "",
					password: ''
				},
				rules: {
					user: [{ required: true, message: '请输入用户名', trigger: ['blur', 'change'] }],
					password: [{ required: true, message: '请输入密码', trigger: ['blur', 'change'] }],
					verificationCode: [{ required: true, message: '请输入验证码', trigger: ['blur', 'change'] }],
				}
			}
		},
		methods: {
			onLogin(formName) {
				if (!formName) return
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							const res = await login(this.ruleForm)	
							// console.log(res)					
							if (res.data.data.access) {
								localStorage.setItem('account', this.ruleForm.user)
								this.$store.commit('saveLogin', res.data.data.applicant)
								localStorage.setItem('province', res.data.data.province)
								localStorage.setItem('city', res.data.data.city)
								localStorage.setItem('county', res.data.data.county)
								localStorage.setItem('town', res.data.data.town)
								localStorage.setItem('level', res.data.data.level)
								localStorage.setItem('intCls', res.data.data.intCls)
								console.log(res)
								if(res.data.data.city == "鞍山市"){
									localStorage.setItem('source', "ASZC")
								}else if(res.data.data.city == "温州市"){
									localStorage.setItem('source', "WZZC")
								}else if(res.data.data.city == "杭州市"){
									localStorage.setItem('source', "BJZC")
								}
								this.$router.push({
								  path:'/dataCount'
								})
							} else {
								this.$message.error('账号或密码错误')
								this.loading = false;
							}
						} catch (e) {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		},
	}
</script>

<style scoped>
	.login-container {
		overflow: hidden;
		height: 100vh;
		width: 100vw;
		display: flex;
		background: url('../../../static/data.png') 100% no-repeat;
		background-size: cover;
	}

	.login-container .left {
		flex: 1;
		position: relative;
		width: 55%;
		min-width: 55%;
		max-width: 55%;
		overflow: hidden;
	}

	.login-container .left>img {
		/* height: 100%;
		width: 100%; */
		object-fit: fill;
		width: 640px;
		height: 640px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		/* opacity: 0.8; */
		
	}

	.login-container .left img::after {
		/* content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2; */
	}

	.login-container .right {
		background-color: transparent;
		padding: 0 70px;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		/* border-left: 1px dotted gray; */
	}
	.right>div{
		width: 431px;
		height: 532px;
		text-align: center;
		/* border:1px solid red; */
		padding:115px 48px 0;
		background: url('../../../static/images/login-right.png') 100% no-repeat;
	}
	.loginT {
		font-size: 30px;
		font-weight: 600;
		margin-bottom: 55px;
		font-family: PingFang SC-Bold, PingFang SC;
		color: #BAE0FF;
	}
	:deep(.el-input__inner){
		width: 400px;
		background: #002C8C;
		color: #fff;
		border:1px dotted #fff;
	}
	:deep(.el-form-item__error){
		color: #fff;
		height: 32px;
		left: 15px;
	}
	:deep(.el-form-item.is-error .el-input__inner){
		border:1px dotted #fff;
	}
	:deep(.el-input__suffix){
		margin-right: 20px;
	}
	.el-form-item {
		margin-bottom: 45px;
	}
     .loginBtn {
		width: 100%;
		background: #002C8C;
		width: 400px;
		height: 60px;
		margin-top: 80px;
		font-size: 20px;
		font-family: PingFang SC-Bold, PingFang SC;
		color: #FFFFFF;
	}
</style>
